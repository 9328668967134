
import { Component, Ref, Vue } from "vue-property-decorator";
import {
  AuditFlowScope,
  InternalMonthlyPlanDto,
  OrganizationUnitDto,
  ProjectDto
} from "@/api/appService";
import ExportWord from "@/components/DownloadWord/index.vue";
import api from "@/api";
import moment from "moment";
import AuditButtons from "@/components/AuditButtons/index.vue";
import { AuditBodyDto } from "@/utils/customDto";

@Component({
  name: "internalMonthlyPlanDetail",
  components: {
    ExportWord,
    AuditButtons
  },
  filters: {
    formatPartnerOrg(orgIds: number[], orgList: OrganizationUnitDto[]) {
      const selectOrgList: string[] = [];
      orgList.map((item: any) => {
        if (orgIds.indexOf(item.id) > -1) {
          selectOrgList.push(item.displayName);
        }
      });
      return selectOrgList.join(",");
    }
  }
})
export default class internalMonthlyPlanDetail extends Vue {
  @Ref() auditButtons!: AuditButtons;
  orgId?: number;
  project: ProjectDto = {};
  detail: InternalMonthlyPlanDto = {
    project: this.project
  };

  auditParams: AuditBodyDto = {
    hostId: undefined,
    hostType: AuditFlowScope.InternalMonthlyPlan,
    auditFlowId: undefined,
    auditStatus: undefined,
    audit: undefined
  };

  partnerOrgList: OrganizationUnitDto[] = [];
  selector: string = "table-detail";
  filename: string = "项目月计划表";
  styles: string = "";

  created() {
    this.fetchOrgAll();

    if (this.$route.params.id) {
      this.orgId = Number(this.$route.params.id);
      this.fetchDetail();
    }
  }

  fetchAuditData() {
    return api.internalMonthlyPlan.audit;
  }

  // 获取详情
  async fetchDetail() {
    await api.internalMonthlyPlan.get({ id: this.orgId }).then(res => {
      this.detail = { ...res };
      this.auditParams!.hostId = res.id + "";
      this.auditParams!.auditFlowId = res.auditFlowId;
      this.auditParams!.auditStatus = res.auditStatus;
      this.auditParams!.audit = res.audit;
      if (res.auditFlowId) {
        (this.auditButtons as any).fetchCanAudit();
      }
    });
  }

  changeAudit() {
    this.fetchDetail();
  }

  // 获取机构
  async fetchOrgAll() {
    await api.organizationUnit
      .getAll({ maxResultCount: 2000 })
      .then((res: any) => {
        if (res.items) {
          this.partnerOrgList = res.items;
        }
      });
  }

  // 返回
  private cancel() {
    this.$router.back();
  }
}
